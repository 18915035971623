import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/analytics';


const firebaseConfig = {
    apiKey: "AIzaSyCQFiNO0K7K0ZH23rpg67ZRb3t8xHAq4-g",
    authDomain: "portfolio-80e4c.firebaseapp.com",
    projectId: "portfolio-80e4c",
    storageBucket: "portfolio-80e4c.appspot.com",
    messagingSenderId: "155995752430",
    appId: "1:155995752430:web:ed2e4188fa755bdf0530ff"
};

// init firebase
firebase.initializeApp(firebaseConfig);

// init services
const projectFirestore = firebase.firestore()
const projectStorage = firebase.storage()
const projectAnalytics = firebase.analytics();

// timestamp
const timestamp = firebase.firestore.Timestamp

export { projectFirestore, projectStorage, projectAnalytics, timestamp }